import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import bitcoin from "@/store/modules/bitcoin.js";
import weather from "@/store/modules/weather.js";
import covidita from "@/store/modules/covidita.js";
import geolocation from "@/store/modules/geolocation.js";
import apidatabase from "@/store/modules/database.js";
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    bitcoin,
    weather,
    covidita,
    geolocation,
    apidatabase
  }
});
