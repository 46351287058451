import Vue from "vue";
import Vuex from "vuex";
import VueGeolocation from "vue-browser-geolocation";
Vue.use(VueGeolocation);
Vue.use(Vuex);
export default {
  state: {
    location: {}
  },
  getters: {
    newLocation: state => state.location
  },
  mutations: {
    GET_LOCATION: (state, res) => {
      state.location = res;
    }
  },
  actions: {
    getLocat({ commit }) {
      VueGeolocation.getLocation({
        enableHighAccuracy: true, //defaults to false
        timeout: Infinity, //defaults to Infinity
        maximumAge: 0 //defaults to 0
      }).then(coordinates => {
        commit("GET_LOCATION", coordinates);
      });
    }
  }
};
