<template>
  <div class="home">
    <div class="container">
      <transition name="covid" appear>
        <div class="d-covid">
          <Covid />
        </div>
      </transition>
      <transition name="search" appear>
        <div class="d-search">
          <VueClock />
          <Weather />
        </div>
      </transition>
      <transition name="btc" appear>
        <div class="d-btc" id="calculator">
          <Bitcoin />
        </div>
      </transition>

      <div class="d-todo">
        <Console />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const Bitcoin = () => import("@/components/Bitcoin.vue");
const Console = () => import("@/components/Console.vue");
const Covid = () => import("@/components/Covid.vue");
const Weather = () => import("@/components/Weather.vue");
const VueClock = () => import("@/components/VueClock.vue");
export default {
  name: "Home",

  components: {
    Bitcoin,
    Console,
    Covid,
    Weather,
    VueClock
  }
};
</script>
<style scoped>
@media screen and (min-width: 400px) {
  .container {
    height: 80vh;
  }
}
@media screen and (min-width: 800px) {
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 99% 1%;
    height: 80vh;
  }
}
.btc-enter {
  transform: rotateY(360deg);

  opacity: 0;
}

.btc-leave-to {
  transform: translateY(-100vh);
  opacity: 1;
}
.btc-leave-active,
.btc-enter-active {
  transition: opacity 1s ease;
  transition: transform 1s ease;
}
.covid-enter {
  transform: scale(3);
  opacity: 0;
}

.covid-leave-to {
  transform: translateY(-80vh);
  opacity: 1;
}
.covid-leave-active,
.covid-enter-active {
  transition: opacity 0.8s ease;
  transition: transform 0.8s ease;
}
.search-enter {
  transform: rotateY(360deg);
  opacity: 0;
}

.search-leave-to {
  transform: translateY(-60vh);
  opacity: 1;
}
.search-leave-active,
.search-enter-active {
  transition: opacity 0.9s ease;
  transition: transform 0.9s ease;
}
.d-btc {
  background-color: #f15946;
  color: cornsilk;
  font-size: 18px;
  padding: 10px;
  border: 8px solid white;
}
.d-covid {
  background-color: #f9c22e;
  padding: 10px;
  color: #0c090d;
  border: 8px solid white;
}
.d-search {
  background-color: #53b3cb;
  border: 8px solid white;
}
.d-todo {
  background-color: #0c090d;
  color: #ffffff;
  grid-column: 1/4;
  text-align: left;
  padding: 4px;
  font-size: 18px;
  height: 20px;
  overflow: scroll;
}
</style>
