<template>
  <div id="app">
    <div id="nav">
      <div class="brand">Desk.</div>
      <router-link to="/"><font-awesome-icon :icon="icon"/></router-link>
      <router-link to="/cards-view"
        ><font-awesome-icon :icon="faUser"
      /></router-link>
      <router-link to="/about"><font-awesome-icon :icon="faCog"/></router-link>
    </div>
    <transition name="page" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
import {
  faHome,
  faCog,
  faCalculator,
  faWater,
  faUser
} from "@fortawesome/free-solid-svg-icons";
export default {
  computed: {
    icon() {
      return faHome;
    },
    faCog() {
      return faCog;
    },
    faCalculator() {
      return faCalculator;
    },
    water() {
      return faWater;
    },
    faUser() {
      return faUser;
    }
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px;
  border-bottom: 1px solid #0c090d;
}

#nav a {
  font-weight: bold;
  color: #f9c22e;
  padding-left: 20px;
  font-size: 32px;
}

#nav a.router-link-exact-active {
  color: #0c090d;
}
.brand {
  font: bold 24px Tahoma, Geneva, sans-serif;
  font-style: normal;
  color: #0c090d;
  background: #fff;
  border: 0px solid #ffffff;
  text-shadow: 0px -1px 1px #222222;

  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
  width: 73px;
  padding: 4px;
  cursor: pointer;
  margin: 0 auto;
  float: left;
}
.brand:active {
  cursor: pointer;
  position: relative;
  top: 2px;
}
.page-enter {
  transform: translateX(100vw);
  opacity: 0;
}

.page-leave-to {
  transform: translateX(-100vw);
  opacity: 1;
}
.page-leave-active,
.page-enter-active {
  transition: opacity 0.5s ease;
  transition: transform 0.5s ease;
}
</style>
