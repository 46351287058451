// api_key bf3c64d7a5681636eb058d37caba9e8e
/**
 * api.openweathermap.org/data/2.5/weather?q={city name}&appid={API key}
 */
import axios from "axios";

const apiClient = axios.create({
  baseURL: `https://e.hanna.academy/api/`,
  withCredentials: false, // This is the default
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export default {
  getTestApi() {
    return apiClient.get("/items");
  },
  getItems() {
    return apiClient.get("/items/getElements");
  },
  addItem(newitem) {
    return apiClient.post("/items/addElement", JSON.stringify(newitem));
  },
  removeItem(i) {
    return apiClient.post("/items/removeElement", JSON.stringify(i));
  },
  updateItem(i) {
    return apiClient.post("/items/updateElement", JSON.stringify(i));
  }
};
