import Vue from "vue";
import Vuex from "vuex";
import wetherDay from "@/services/weather.js";
import VueGeolocation from "vue-browser-geolocation";
Vue.use(VueGeolocation);
Vue.use(Vuex);
export default {
  state: {
    wDay: {
      feels_like: 1,
      humidity: 1,
      pressure: 1,
      temp: 1,
      temp_max: 1,
      temp_min: 1
    },
    wCity: "City",
    lon: 12,
    lat: 41
  },
  getters: {
    newWeather: state => state.wDay,
    newCity: state => state.wCity,
    newlon: state => state.lon,
    newlat: state => state.lat
  },
  mutations: {
    GET_WEATHER: (state, res) => (state.wDay = res.main),
    GET_CITY: (state, res) => (state.wCity = res),
    GET_LAT: (state, res) => (state.lon = res.lat),
    GET_LON: (state, res) => (state.lon = res.lng)
  },
  actions: {
    async getcoo({ commit }) {
      VueGeolocation.getLocation({
        enableHighAccuracy: true, //defaults to false
        timeout: Infinity, //defaults to Infinity
        maximumAge: 0 //defaults to 0
      }).then(coordinates => {
        commit("GET_LAT", coordinates);
        commit("GET_LON", coordinates);
      });
    },
    async getWeather({ commit }) {
      await VueGeolocation.getLocation({
        enableHighAccuracy: true, //defaults to false
        timeout: Infinity, //defaults to Infinity
        maximumAge: 0 //defaults to 0
      }).then(coordinates => {
        wetherDay.getWeatherApi(coordinates.lat, coordinates.lng).then(res => {
          console.log(res.data);
          commit("GET_WEATHER", res.data);
          commit("GET_CITY", res.data.name);
        });
      });
    }
  }
};
