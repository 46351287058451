import Vue from "vue";
import Vuex from "vuex";
import apidatabase from "@/services/apidatabase.js";

Vue.use(Vuex);
export default {
  state: {
    response: {},
    items: []
  },
  getters: {
    newResponse: state => state.response,
    newItems: state => state.items
  },
  mutations: {
    GET_TEST: (state, res) => {
      state.response = res;
    },
    GET_ITEMS: (state, res) => {
      state.items = res;
    },
    ADD_ITEM: (state, res) => {
      state.items = res;
    },
    RM_ITEM: (state, res) => {
      state.items = res;
    },
    UPDATE_ITEM: (state, res) => {
      state.items = res;
    }
  },
  actions: {
    async getTest({ commit }) {
      const res = await apidatabase.getTestApi();
      commit("GET_TEST", res.data);
    },
    async getItems({ commit }) {
      const res = await apidatabase.getItems();
      commit("GET_ITEMS", res.data);
    },
    async addItem({ commit }, newitem) {
      const res = await apidatabase.addItem(newitem);
      commit("ADD_ITEM", res.data);
    },
    async removeItem({ commit }, i) {
      const res = await apidatabase.removeItem(i);
      commit("RM_ITEM", res.data);
    },
    async updateItem({ commit }, i) {
      const res = await apidatabase.updateItem(i);
      console.log(i);
      commit("UPDATE_ITEM", res.data);
    }
  }
};
