// api_key bf3c64d7a5681636eb058d37caba9e8e
/**
 * api.openweathermap.org/data/2.5/weather?q={city name}&appid={API key}
 */
import axios from "axios";
const API_KEY = "bf3c64d7a5681636eb058d37caba9e8e";
const apiClient = axios.create({
  baseURL: `https://api.openweathermap.org/data/2.5`,
  withCredentials: false, // This is the default
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export default {
  getWeatherApi(lat, lon) {
    return apiClient.get(
      "/weather?lat=" + lat + "&lon=" + lon + "&units=metric&appid=" + API_KEY
    );
  }
};
