import Vue from "vue";
import Vuex from "vuex";
import covidIta from "@/services/coviditalia.js";

Vue.use(Vuex);
export default {
  state: {
    last: {}
  },
  getters: {
    newLast: state => state.last
  },
  mutations: {
    GET_STATS: (state, res) => {
      state.last = res[0];
    }
  },
  actions: {
    async getStatistic({ commit }) {
      const res = await covidIta.getLast();
      commit("GET_STATS", res.data);
    }
  }
};
