import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default {
  state: {
    btc: {},
    prices: []
  },
  getters: {
    myBtc: state => state.btc,
    myPrices: state => state.prices
  },
  mutations: {
    GET_BTC: (state, res) => {
      state.btc = res;
    },
    GET_PRICES: (state, res) => {
      state.prices.unshift(res);
    }
  },
  actions: {
    getBtc({ commit }) {
      const res = JSON.parse(
        '{"id": 1, "price": 1000, "symbole": "BTC", "currency": "USD"}'
      );
      commit("GET_BTC", res);
    },
    initWebsocket({ commit }) {
      let ws = new WebSocket("wss://ws.bitstamp.net");
      let subscribeMsg = {
        event: "bts:subscribe",
        data: {
          channel: "live_trades_btceur"
        }
      };

      ws.onopen = function() {
        ws.send(JSON.stringify(subscribeMsg));
      };

      ws.onmessage = function(evt) {
        let response = JSON.parse(evt.data);
        /**
         * This switch statement handles message logic. It processes data in case of trade event
         * and it reconnects if the server requires.
         */
        switch (response.event) {
          case "trade": {
            //serializeTrade(response.data);
            commit("GET_BTC", response.data);
            commit("GET_PRICES", response.data.price);
            break;
          }
          case "bts:request_reconnect": {
            this.initWebsocket();
            break;
          }
        }
      };
      /**
       * In case of unexpected close event, try to reconnect.
       */
      ws.onclose = function() {
        console.log("Websocket connection closed");
        this.initWebsocket();
      };
    }
  }
};
